.MobileMenuContainer {
    width: 100%;
    background-color: rgb(58, 57, 53);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    font-family: "Nunito", sans-serif;
}

.menuClosed {
    height: 120px;
}

.menuOpened {
    height: 100vh;
}

.Menu {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    width: 20%;
    height: 70%;
    margin-left: 20px;
    display: flex;
}

.hamburgerIcon {
    height: 80%;
    margin-right: 20px;
}

.innerMenu {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
    gap: 40px;
}

.NavItem {
    font-size: 1em;
    font-weight: 700;
    cursor: pointer;
    position: relative;
    height: 45%;
    color: white;
    font-size: 1.1em;
 }

.nav {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 60%;
    width: 100%;
    color: white;
}

.leaveTicketButton {
    width: 60%;
    height: 70px;
}

.phoneNumber {
    color: white;
}