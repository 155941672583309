.TicketFormContainer {
    width: 500px;
    height: 700px;
    background-color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Nunito", sans-serif;
    z-index: 1000;
    animation: show 0.5s;
    animation-fill-mode: both;
}

.formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 80%;
    height: 90%;
    gap: 20px;
}

@keyframes show {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.title {
    font-size: 2em;
}

.cross {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}

.textField {
    width: 100%;
    font-family: inherit;
}

.comment {
    font-family: inherit;
    width: 100%;
    height: 92px;
}

.submitButton{
    width: 80%;
    height: 70px;
    transition: 0.5s;
}

.submitButton:hover {
    width: 80%;
    height: 70px;
    background-color: black;
}

@media screen and (max-width: 1000px) {
    .title {
        font-size: 1.8em;
    }
}

@media screen and (max-width: 600px) {
    .TicketFormContainer {
        width: 320px;
        height: 650px;
    }

    .title {
        font-size: 1.8em;
    }

}
