.mapBlock {
    width: 100%;
    height: 700px;
    display: flex;
    justify-content: center;
    font-family: "Nunito", sans-serif;
    background-color: white;
}

.map {
    border: 0;
    width: 50%;
    height: 700px;
}

.infoContainer {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.info {
    margin-top: 5%;
    margin-left: 5%;
}

.leaveTicketButton {
    width: 30%;
    height: 60px;
    margin-top: 50px;
    transition: 0.5s;
}

.leaveTicketButton:hover {
    background-color: black;
}

.address {
    margin-top: 20px;
    font-size: 1.1em;
    width: 70%;
    color: gray;
}

.contactsContainer {
    margin-top: 50px;
    font-size: 1.5em;
    list-style-type: none;
    color: rgb(6, 6, 80);
    cursor: pointer;
    margin-top: 40px;
    padding: 0;
}


.link {
    color: rgb(4, 35, 102);
    font-style: inherit;
    text-decoration: none;
}

@media screen and (max-width: 1000px) {
    .mapBlock {
        flex-direction: column;
        height: 1000px;
    }

    .infoContainer {
        width: 100%;
        height: 650px;
    }

    .map {
        width: 100%;
    }

    .leaveTicketButton {
        width: 50%;
        height: 80px;
    }
}

@media screen and (max-width: 600px) {
    .mapBlock {
        flex-direction: column;
        height: auto;
    }

    .infoContainer {
        width: 100%;
        height: auto;
        padding-bottom: 5%;
    }

    .map {
        width: 100%;
        height: 400px;
    }

    .info {
        margin-left: 1%;
    }

    .address {
        width: 90%;
    }

    .leaveTicketButton {
        width: 99%;
        height: 80px;
    }
}