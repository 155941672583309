.header {
    width: 100%;
    height: 800px;
    background:
        linear-gradient(rgba(31, 31, 30, 0.8), rgba(77, 80, 61, 0.5)),
        url('../../img/header.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    display: flex;
    justify-content: center;
}

.headerContainer {
    width: 95%;
    height: 100%;
}

.mainInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    padding-left: 10%;
    margin-top: 5%;
    font-family: "Nunito", sans-serif;
}

.title {
    color: white;
    font-weight: 700;
    font-size: 2.8em;
}

.description {
    color: white;
    width: 80%;
    font-size: 1.8em;
}

.aboutUsButton {
    width: 35%;
    height: 70px;
    font-size: 1.2em;
    transition: background-color 0.5s ease, color 0.5s ease;
}

.aboutUsButton:hover {
    background-color: rgb(87, 209, 87);
    color: white;
}

@media screen and (max-width: 1000px) {
    .mainInfo {
        width: 90%;
    }

    .title {
        font-size: 2.5em;
    }

    .description {
        font-size: 1.5em;
    }

    .header {
        height: 600px;
    }

    .aboutUsButton {
        width: 80%;
    }
}

@media screen and (max-width: 600px) {
    .mainInfo {
        width: 100%;
        align-self: center;
        padding-left: 1%;
    }

    .title {
        font-size: 2em;
    }

    .description {
        font-size: 1.2em;
        width: 100%;
    }

    .header {
        height: 450px;
    }

    .aboutUsButton {
        width: 100%;
    }
}