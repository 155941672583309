@counter-style marker-font {
	system: cyclic;
	symbols: "✓";
	suffix: " ";
}

.textBlock {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    font-family: "Nunito", sans-serif;
}

.info {
    width: 50%;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding-bottom: 5%;
}

.title {
    font-size: 2.4em;
    font-weight: bold;
    margin-left: 11%;
    margin-top: 5%;
}

.article {
    margin-left: 10%;
}

.img {
    width: 50%;
    background-position: right;
    background-size: cover;
    background-repeat: no-repeat;
}

.text {
    font-size: 1.3em;
    font-weight: 500;
    margin: 0;
    width: 90%;
}

.list {
    list-style-type: marker-font;
    font-size: 1.4em;
    display: flex;
    flex-direction: column;
    gap: 40px;
    font-weight: 500;
    margin-top: 5%;
}

@media screen and (max-width: 1000px) {
    .textBlock {
        flex-direction: column;
    }

    .info {
        width: 100%;
        margin-bottom: 20px;
    }

    .article {
        width: 80%;
    }

    .text {
        width: 80%;
    }

    .text {
        width: 100%;
        text-align: justify;
    }

    .title {
        font-size: 2em;
    }

    .list {
        font-size: 1.3em;
        gap: 20px;
    }

    .img {
        width: 100%;
        height: 400px;
    }

}

@media screen and (max-width: 600px) {
    .textBlock {
        flex-direction: column;
    }

    .info {
        width: 100%;
        margin-bottom: 20px;
    }

    .title {
        font-size: 1.8em;
        margin-left: 0;
        text-align: center;
    }

    .list {
        font-size: 1.3em;
        gap: 20px;
    }

    .article {
        margin-left: 1%;
        width: 98%;
    }

    .img {
        width: 100%;
        height: 300px;
    }
}