.reviews {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Nunito", sans-serif;
    background-color: white;
}

.review {
    width: 80%;
    border-bottom: 1px rgb(80, 80, 80) solid;
    padding-top: 2%;
    padding-bottom: 1%;
}

.text {
    font-size: 1.2em;
}