.footer {
    width: 100%;
    height: 300px;
    background-color: rgb(28, 28, 66);
    display: flex;
    justify-content: center;
    font-family: "Nunito", sans-serif;
}

.footerContainer {
    width: 60%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footnav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: white;
    font-size: 1.1em;
}

.NavItem {
    cursor: pointer;
    text-decoration: none;
    color: white;
}

.contactsContainer {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

.contact {
    margin-top: 0;
}

@media screen and (max-width: 1000px) {
    .footer {
        height: 500px;
    }

    .footnav {
        flex-direction: column;
        align-items: center;
        gap: 20px;
        margin-top: 50px;
    }
}

@media screen and (max-width: 600px) {

    .footer {
        height: 600px;
    }

    .footnav {
        flex-direction: column;
        align-items: center;
        gap: 20px;
        margin-top: 100px;
    }
}